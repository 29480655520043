@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

.custom-navbar {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    align-items: center;
    margin: 0;
}

.nav-list li {
    margin: 0 15px;
}

.nav-list a {
    text-decoration: none;
    color: #333;
    position: relative;
}

.nav-list a::after {
    content: '';
    width: 0%;
    height: 3px;
    background: darkred;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: 0.3s;
}

.nav-list a:hover::after {
    width: 100%;
}